// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Highlights from 'components/Contentful/Highlights'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Wärmepumpe und Photovoltaik-Anlage live erleben"
        meta={[
          { name: 'description', content: 'Haben Sie schon einmal live erlebt, wie eine installierte Wärmepumpe oder Photovoltaik-Anlage funktioniert? Wissen Sie wie laut eine WP ist? Was macht eigentlich ein Wechselrichter? Datum reservieren und reinschauen!' },
          { name: 'keywords', content: 'Tag der offenen Wärmepumpe, Tag der offenen Photovoltaik-Anlage, Tag der offenen Türe' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='11md4pPWTeO3uW2TQwCtOE-361tCPhSboT33glHJvpKqR'
          id='tage-der-offenen-waermepumpe-in-ihrer-region'
        >
          <Highlights
            {...{
    "type": "highlights",
    "id": "361tCPhSboT33glHJvpKqR",
    "updatedAt": "2024-09-20T02:38:44.464Z",
    "title": "Tage der offenen Wärmepumpe & Photovoltaik-Anlage in Ihrer Region",
    "description": "<p>Haben Sie schon einmal live erlebt, wie eine installierte Wärmepumpe funktioniert? Wissen Sie wie laut eine Wärmepumpe ist? Was macht eigentlich ein Wechselrichter bei einer Photovoltaik-Anlage?\nErleben Sie die Wärmepumpe &#x26; Photovoltaik-Anlage mit Ihrer ganzheitlichen Installation direkt vor Ort und lassen Sie sich von Experten beraten.</p>\n",
    "highlights": [
        {
            "type": "highlight",
            "id": "4BQfnw1zpIGjz0kKsq2UQ4",
            "updatedAt": "2024-09-20T02:38:30.955Z",
            "title": "Wil SG am Samstag, 26. Oktober 2024",
            "description": "Schauen Sie am Samstag, 26. Oktober 2024 in 9500 Wil am Langeggweg 15 bei Zünds vorbei. Die Heizungsmacher AG hat bei Ihnen im Jahr 2024 eine aussenaufgestellte Luft/Wasser Wärmepumpe WLW186i-7 AR E von Buderus kombiniert mit einer Photovoltaik-Anlage mit Solaredge-Wechselrichter und JinkoSolar Tiger Neo 435W-Modulen installiert.",
            "image": {
                "description": "Heizungsmacher Icon effiziente Prozesse aus einer Hand",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/2BtMtnSDKcm0QYsQ6YiyK2/bec15b54ecbd2c752fce73f077c3589a/effiziente_prozesse_w.svg"
            },
            "link": {
                "type": "link",
                "id": "2KfbHTpjSYEwWYxnMpzZbP",
                "updatedAt": "2024-09-20T02:38:24.523Z",
                "label": "Flyer herunterladen",
                "url": "https://go.heizungsmacher.ch/l/503181/2024-09-19/3h1xksg/503181/1726799796kyRTFLsQ/240918_Flyer_A5_TdoWPPV_Z__nd__Wil.pdf"
            }
        }
    ],
    "anchorName": "tage-der-offenen-waermepumpe-in-ihrer-region"
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
